import React from 'react'
import { Banner } from './Banner'
import { Contact } from './Contact'
import { Features } from './Features'
import { Footer } from './Footer'
import { LikeOilForEngine } from './LikeOilForEngine'
import { Navbar } from './Navbar'
import { OurWorkProcess } from './OurWorkProcess'
import { BlogsLanding } from "./BlogsLanding";
import AppContext from '../AppContext';
import { useContext } from 'react';
import servicebnnerimg from '../Assets/images/img1.png'
import earnpointimg from '../Assets/images/img2.png'
import iconoil from '../Assets/images/oilchanges.png'
import tirerotation from '../Assets/images/tirerotations.png'
import wheelalignment from '../Assets/images/wheelalignments.png'
import tires from '../Assets/images/tries.png'
import brakepads from '../Assets/images/brakepads.png'
import batteries from '../Assets/images/batteries.png'
import airfilters from '../Assets/images/airfilters.png'
import wiperblades from '../Assets/images/wiperblades.png'
import multipoints from '../Assets/images/inspections.png'
import arrow from '../Assets/images/up-arrows.png'
import favorite from '../Assets/images/img3.png'
import vehicleapperance from '../Assets/images/img4.png'
import pffe from '../Assets/images/img5.png'
import eirp from '../Assets/images/img7.png'
import withibizipoints from '../Assets/images/img6.png'


import { DownLoadAppScetion } from './DownLoadAppScetion'

import { Loader } from './Loader'
import { Para } from './Para'
import { Product } from './Product'
import { LifeCycle } from './LifeCycle'
import Tabslayout from './TabsLayout'


export const Landing = () => {
  const myContext = useContext(AppContext);

  const servicesModel = [
    {
      icon : iconoil,
      title : 'Oil Change'
    },
    {
      icon : tirerotation,
      title : 'Tire Rotations'
    },
    {
      icon : wheelalignment,
      title : 'Wheel Alignments'
    },
    {
      icon : tires,
      title : 'Tires'
    },
    {
      icon : brakepads,
      title : 'Brake Pads'
    },
    {
      icon : batteries,
      title : 'Batteries'
    },
    {
      icon : airfilters,
      title : 'Air Filters'
    },
    {
      icon : wiperblades,
      title : 'Wiper Blades'
    },
    {
      icon : multipoints,
      title : 'Inspections'
    }
    // {
    //   icon : otherimg,
    //   title : 'Other'
    // }
  ]

  return (
    <>
      <Navbar/>
      {
        myContext.loadingstate === false &&
        <Loader/>
      }
      {
        myContext.activepagename === 'home' ?
        <>
          {/* <StickyHeroSection/> */}

            <Banner from='home'/>
            <LikeOilForEngine/>
            <Product/>
            <Features/>
            <OurWorkProcess/> 
            <LifeCycle/>
            <Contact/>
            
        </> 
        :
          myContext.activepagename === 'vehicles' ?
          <>
          
            <Banner from='vehicle'/>    
           
            {/* <DealershipOnboard/> */}
            {/* <ImporoveYourBusiness/> */}
            
          
          


          <div className='Common__Section slider_wrapper'>
            <div className='Common__Section_section_wrapper'>
              <Tabslayout/>
            </div>
          </div>



          <DownLoadAppScetion
              title='Download App' 
              para='' 
              imgPosition='left'
              sectionidentity='downloadsection'
            />
            
          <div className={`Common__Section vehicles_favorites`}>
              <div className='Common__Section_section_wrapper'>
                  <div className='flex'>
                      <div className='left'>
                          <h2 className='banner__title strong'>
                              Monitor<br></br> Your <span>Favorites</span>
                          </h2>
                          <Para para='Within the app you can mark your favorites
                          and receive a notification when a price
                          change occurs.' style='normal' size='xl'/>
                      </div>
                      <div className='right'>
                          <img src={favorite} alt='BannerRightImage'/>
                      </div>
                  </div>
              </div>
          </div>
            
          </>
          :
          myContext.activepagename === 'protectionplan' ?
          <>
            <Banner from='protectionplan'/>
            <div className={`Common__Section pp_vehiclesapperence`}>
                <div className='Common__Section_section_wrapper right_style'>
                    <div className='flex'>
                        <div className='left'>
                          <h2 className='banner__title strong'>
                            Protect Your<br></br> <span>Vehicle’s Appearance</span>
                          </h2>
                          <Para 
                          para='
                            You can purchase protection products.
                            These could be interior and exterior
                            protection, paintless dent repair, or
                            tire and wheel protection.
                          ' style='normal' size='xl'/>
                          <Para 
                          para='
                          *The offerings will vary based on your dealership.
                          ' style='italic' size='md'/>
                        </div>
                        <div className='right'>
                            <img src={vehicleapperance} alt='BannerRightImage'/>
                        </div>
                    </div>
                </div>
            </div>
            <DownLoadAppScetion
              title='Download App' 
              para='' 
              sectionidentity='downloadsection'
            />
            <div className={`Common__Section protectionplan_futureexpenses`}>
              <div className='Common__Section_section_wrapper'>
                  <div className='flex'>
                      <div className='left'>
                          <h2 className='banner__title strong'>
                            Prepare for <br></br> 
                            <span>Future Expenses</span>
                          </h2>
                          <Para para='Save money for future expenses by purchasing a,' style='normal' size='xl'/>
                          <ul className='Common__Section__Bullets'>
                          <li>Vehicle Service Contract</li>
                          <li>Pre-paid Maintenance Contract.</li>
                          <li>Key Replacement</li>
                          </ul>
                          <Para 
                          para='
                          *The offerings will vary based on your dealership.
                          ' style='italic' size='md'/>
                      </div>
                      <div className='right'>
                          <img src={pffe} alt='BannerRightImage'/>
                      </div>
                  </div>
              </div>
            </div>
          </>
          :
          myContext.activepagename === 'rewards' ?
          <>
            <Banner from='rewards'/>
            <div className={`Common__Section section_rewadspoints`}>
              <div className='Common__Section_section_wrapper'>
                  <div className='flex'>
                      <div className='left'>
                          <h2 className='banner__title strong'>
                            Earn IBIZI <br></br> 
                            <span>Reward</span><br></br>
                            Points
                          </h2>
                          <ul className='Common__Section__Bullets'>
                            <li>Add a Vehicle and Earn Points!</li>
                            <li>Answer Questions and Earn Points!</li>
                            <li>Complete a Service Appointment and Earn Points!</li>
                          </ul>
                          <Para 
                          para='
                          *The offerings will vary based on your dealership.
                          ' style='italic' size='md'/>
                      </div>
                      <div className='right'>
                          <img src={eirp} alt='BannerRightImage'/>
                      </div>
                  </div>
              </div>
            </div>
            
            <DownLoadAppScetion
              title='Download App' 
              para='' 
              sectionidentity='downloadsection'
            />
           <div className={`Common__Section pp_vehiclesapperence`}>
              <div className='Common__Section_section_wrapper right_style'>
                  <div className='flex'>
                      <div className='left'>
                        <h2 className='banner__title strong'>
                          With IBIZI<br></br> <span>Reward</span> Points
                        </h2>
                        <ul className='Common__Section__Bullets'>
                            <li>Use Points for Service Appointments!</li>
                            <li>Receive a Discount on Protection Plans with Points!</li>
                          </ul>
                      </div>
                      <div className='right'>
                          <img src={withibizipoints} alt='BannerRightImage'/>
                          {/* <span className='square square1'>
                            <img src={ico1} alt='BannerRightImage'/>
                          </span>
                          <span className='square square2'>
                            <img src={ico2} alt='BannerRightImage'/>
                          </span>
                          <span className='square square3'>
                            <img src={ico3} alt='BannerRightImage'/>
                          </span> */}
                      </div>
                  </div>
              </div>
            </div>
          </>
          :
          myContext.activepagename === 'services' ?
            <>
              <Banner from='services'/>
              <div className={`Common__Section services_scheduleaservice`}>
                  <div className='Common__Section_section_wrapper right_style'>
                      <div className='flex'>
                          <div className='left'>
                            <h2 className='banner__title strong'>
                                Schedule a<br></br> <span>Service Appointment</span> <br></br> and <span>Purchase Service</span> Items
                            </h2>
                            <Para 
                            para='
                              Use the app to quickly and easily schedule
                              the day and time for your next service need.
                              You can also purchase service items directly
                              in the app!
                            ' style='normal' size='xl'/>
                          </div>
                          <div className='right'>
                              <img src={servicebnnerimg} alt='BannerRightImage'/>
                              {/* <span className='square square1'>
                                <img src={ico1} alt='BannerRightImage'/>
                              </span>
                              <span className='square square2'>
                                <img src={ico2} alt='BannerRightImage'/>
                              </span>
                              <span className='square square3'>
                                <img src={ico3} alt='BannerRightImage'/>
                              </span> */}
                          </div>
                      </div>
                  </div>
              </div>
              
              <div className='Cards_section'>
                {
                    servicesModel.map((user) => (
                        <div className='Card'>
                          <div className='Card_img_wrapper'>
                            <img className='' src={user.icon} alt='BannerRightImage'/>
                          </div>
                          <h4>{user.title}</h4>
                        </div>
                    ))
                }
              </div>
              <DownLoadAppScetion
                title='Download App' 
                para='' 
                sectionidentity='downloadsection'
              />
              <div className={`Common__Section services_earnpoints`}>
                  <div className='Common__Section_section_wrapper right_style'>
                      <div className='flex'>
                          <div className='left'>
                            <h2 className='banner__title strong'>
                                Earn<br></br> <span>IBIZI</span> Points
                            </h2>
                            <Para para='Earn IBIZI reward points by scheduling service appointments.' style='normal' size='xl'/>
                          </div>
                          <div className='right'>
                              <img src={earnpointimg} alt='BannerRightImage'/>
                          </div>
                      </div>
                  </div>
              </div>
              
            </>
          :
          myContext.activepagename === 'contact'?
          <>
            {/* <StickyHeroSection/> */}
            <Banner from='contact'/>
            
            <Contact from='contctpage'/>
          </> 
          :
          myContext.activepagename === 'blogs'?    
            <BlogsLanding/>
          :
          <h4>404 Page Not Found</h4>
          
        
      }
      <Footer/>
      <a href='#Home' className='scrolltopicon'>
        <img src={arrow} loading="lazy"  alt='sterick'/>
      </a>
    </>
   
  )
}
