import React, { useState, useEffect } from "react";
import { Para } from './Para'
import twitter from '../Assets/images/twitter.png'
import fb from '../Assets/images/facebook.png'
import insta from '../Assets/images/insta.png'
import linkedin from '../Assets/images/linkedinicon.png'

import footterimg from '../Assets/images/footerimg.png'
import gplay from '../Assets/images/gplay.png'
import astore from '../Assets/images/astore.png'
import sterick from '../Assets/images/sterick.png'
import { Title } from './Title'
import AppContext from '../AppContext';
import { useContext } from 'react';



export const Footer = () => {
const myContext = useContext(AppContext);
const [currentYear, setCurrentYear] = useState(null);

useEffect(() => {
  setCurrentYear(
    "© " + new Date().getFullYear() + " IBIZI. All Rights Reserved"
  );
}, []);
  return (
    <div className="footer">
      <div className="footer__top__Layer">
        <div className="footer__top__Layer_internal flex">
          <Para
            para="Creating a Personalized Experience"
            style="normal"
            size="xl"
          />
          <div className="flex">
            <a href="#">
              <img className="" src={gplay} alt="BannerRightImage" />
            </a>
            <a href="https://apps.apple.com/pk/app/ibizi/id1659590288">
              <img className="" src={astore} alt="BannerRightImage" />
            </a>
          </div>
        </div>
        <div className="footer__top__Layer_internal1">
          <div className="footer__top__Layer_internal1_right">
            <div className="footer__top__Layer_internal1_right_top">
              <div className="footer__top__Layer_internal1_right_top_left">
                <h4>Quick Links</h4>
                <ul>
                  <li>
                    <a
                      href="#Home"
                      onClick={() => {
                        myContext.setactivepagename("home");
                        myContext.setstickynav(false);
                      }}>
                      <img src={sterick} alt="sterick" />
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="#about"
                      onClick={() => {
                        myContext.setactivepagename("home");
                        myContext.setstickynav(false);
                      }}>
                      <img src={sterick} alt="sterick" />
                      About App
                    </a>
                  </li>
                  <li>
                    <a
                      href="#Work"
                      onClick={() => {
                        myContext.setactivepagename("home");
                        myContext.setstickynav(false);
                      }}>
                      <img src={sterick} alt="sterick" />
                      How It Works
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        myContext.setactivepagename("home");
                        myContext.setstickynav(false);
                      }}>
                      <img src={sterick} alt="sterick" />
                      Support
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://dealership.ibizi.com//DealerArea/DealerAdmin/Privacy"
                      target="blank">
                      <img src={sterick} alt="sterick" />
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://dealership.ibizi.com/DealerArea/DealerAdmin/TermsAndConditions"
                      target="blank">
                      <img src={sterick} alt="sterick" />
                      Term of Use
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer__top__Layer_internal1_right_bottom flex">
              <a
                className="logo__wrapper-footer"
                href="#Home"
                onClick={() => {
                  myContext.setactivepagename("home");
                  myContext.setstickynav(false);
                  var elements = document.querySelector(".nav__dropdown");
                  elements.classList.remove("active");
                  // myContext.setloadingstate(false);
                }}>
                <Title Title="IBIZI" size="sm" />
              </a>

              <Para para={currentYear} style="normal" size="sm" />
            </div>
          </div>
        </div>
      </div>

      <div className="footer__top__Layer1">
        <div className="footer__top__Layer1_top">
          <img className="" src={footterimg} alt="BannerRightImage" />
        </div>
        <div className="footer__top__Layer1_bottom">
          <div className="footer__top__Layer1_bottom_item_3">
            <img className="" src={linkedin} alt="BannerRightImage" />
          </div>
          <div className="footer__top__Layer1_bottom_item_1">
            <img className="" src={twitter} alt="BannerRightImage" />
          </div>
          <div className="footer__top__Layer1_bottom_item_2">
            <img className="" src={fb} alt="BannerRightImage" />
          </div>
          <div className="footer__top__Layer1_bottom_item_3">
            <img className="" src={insta} alt="BannerRightImage" />
          </div>
        </div>
      </div>
    </div>
  );
}
