import React from 'react'
import sterick from '../Assets/images/sterick.png'
import close from '../Assets/images/close.png'
import humbrgr from '../Assets/images/humburger.png'
import logo from '../Assets/images/IBIZILogo.png'
import AppContext from '../AppContext';
import { useContext } from 'react';



export const Navbar = () => {

  const myContext = useContext(AppContext);
  
  function toggleDropDown(){
    var element = document.querySelector(".nav__dropdown");
    // element.classList.toggle("active");
    if (element.classList.contains('active')) {
      element.classList.remove("active");
    } else {
      element.classList.add("active");
    }
  }

  function togglesidebar(){
    var element = document.querySelector(".navitem_wrapper");
    element.classList.toggle("open");
  }

  function closesidepanel(){
    var element = document.querySelector(".navitem_wrapper");
    element.classList.remove("open");
    var elements = document.querySelector(".nav__dropdown");
    elements.classList.remove("active");
  }
  
  return (
    
    <div className={`navbar__wrapper ${myContext.stickynav ? 'stickynav' : ''}`}>
        <a className='logo__wrapper' href='#Home' onClick={() => {
          myContext.setactivepagename('home');
          myContext.setstickynav(false);
          var elements = document.querySelector(".nav__dropdown");
          elements.classList.remove("active");
          // myContext.setloadingstate(false);
        }} >
          <img src={logo} alt='sterick'/>
        </a>
        <span className='humbuerger'  onClick={togglesidebar}>
          <img src={humbrgr} alt='sterick'/>
        </span>
        <ul className='navitem_wrapper'>
            
            <li className='nav_items'  onClick={closesidepanel}>
              <a href='#Home' onClick={() => {
                myContext.setactivepagename('home');
                myContext.setstickynav(false);
                var elements = document.querySelector(".nav__dropdown");
                elements.classList.remove("active");
                // myContext.setloadingstate(false);
              }}>
                Home
              </a>
            </li>
            <li className='nav_items'><img src={sterick} alt='sterick'/></li>
            <li className='nav_items'  onClick={toggleDropDown}>
              <a href='javascript:void(0)'>About Product</a>
              <div className='nav__dropdown' onClick={closesidepanel}>

                <li className='nav_items'  onClick={closesidepanel}>
                  <a href='#Home' onClick={() => {
                    
                    myContext.setactivepagename('vehicles');
                    myContext.setstickynav(true);
                    // myContext.setloadingstate(false);
                  }}>
                    <img src={sterick} alt='sterick'/>
                    Vehicles
                  </a>
                </li>

                <li className='nav_items' onClick={closesidepanel}>
                  <a href='#Home' onClick={() => {
                    myContext.setactivepagename('services');
                    myContext.setstickynav(true);
                    // myContext.setloadingstate(false);
                  }}>
                    <img src={sterick} alt='sterick'/>
                    Service
                  </a>
                </li>

                <li className='nav_items' onClick={closesidepanel}>
                  <a href='#Home' onClick={() => {
                    myContext.setstickynav(true);
                    myContext.setactivepagename('protectionplan');
                    // myContext.setloadingstate(false);
                  }}>
                    <img src={sterick} alt='sterick'/>
                    Protection Plans
                  </a>
                </li>

                <li className='nav_items' onClick={closesidepanel}>
                  <a href='#Home' onClick={() => {
                    myContext.setstickynav(true);
                    myContext.setactivepagename('rewards');
                    // myContext.setloadingstate(false);
                  }}>
                    <img src={sterick} alt='sterick'/>
                    Rewards
                  </a>
                </li>
              </div>
            </li>
            <li className='nav_items' onClick={closesidepanel}><img src={sterick} alt='sterick'/></li>
            <li className='nav_items' onClick={closesidepanel}>
              <a href='#Features' onClick={() => {
                  myContext.setactivepagename('home');
                  myContext.setstickynav(false);
                }}>
                Features
              </a>
            </li>
            <li className='nav_items'><img src={sterick} alt='sterick'/></li>
            <li className='nav_items' onClick={closesidepanel}>
              <a href='#Blogs' onClick={() => {
                myContext.setactivepagename('blogs');
                myContext.setstickynav(true);
                myContext.setBlogDetailPageIsOpen(false);
              }}>
                Blog
              </a>
            </li>
            <li className='nav_items'><img src={sterick} alt='sterick'/></li>
            <li className='nav_items' onClick={closesidepanel}>
              <a href='#Contact' onClick={() => {
                myContext.setactivepagename('contact');
                myContext.setstickynav(true);
              }}>
                Contact Us
              </a>
            </li>
            <span className='close_sidebar' onClick={closesidepanel}><img src={close} alt='sterick'/></span>
        </ul>    
    </div>
  )
}
