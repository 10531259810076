import React, { useState } from "react";
import { Para } from "./Para";
import { Title } from "./Title";
import { Banner } from "./Banner";
import AppContext from "../AppContext";
import { useContext } from "react";
import rewardbox from "../Assets/images/blogbanner.png";
import blog2image from "../Assets/images/ibiziblog2.jpg";
import blogimg1 from "../Assets/images/blogstarr.jpg";
import blogimg2 from "../Assets/images/blogimgg.png";
import blog1detailbanner from "../Assets/images/bannerdetailbanner.png";
import blog2detailbanner from "../Assets/images/IBIZIDetailBanner2.jpg";
import blog2detailsectionimage from "../Assets/images/blog2sectionimg.png";
// import blogimg2 from "../Assets/images/blogimgg.png";

const BlogModel = [
  {
    BlogId: 1,
    Date: "25-july-2023",
    Title: "How Auto Dealers Can Resolve Common Pain Points With IBIZI",
    Category: "Business",
    image: blog2image,
    Shortdescription:
      "In today’s automotive industry landscape, dealerships constantly strive to enhance the buyer experience, streamline operations, and strengthen customer loyalty. However, the road to achieving these goals is often paved with challenges. That’s where IBIZI comes in! IBIZI is an innovative app designed to transform how auto dealerships operate and interact with their customers, effectively addressing many of their most common pain points.",
  },
  {
    BlogId: 0,
    Date: "25-july-2023",
    Title: "Customer Retention Helps Drive Dealership Success",
    Category: "Business",
    image: rewardbox,
    Shortdescription:
      "Customer retention refers to the ability of a business to keep their existing customers coming back for repeat business. It's about fostering strong and lasting relationships with clients beyond the initial sale. When customers return to a dealership for service, maintenance, or to purchase a new vehicle, it demonstrates their satisfaction and trust in the dealership's products and services. Simply put, the ROI for customer loyalty cannot be overstated!",
  },
];




export const BlogsLanding = () => {
  const myContext = useContext(AppContext);
  const [OpenBlogId, setOpenBlogId] = useState(null);

  const renderContentBlogContent = React.useCallback(() => {
    switch (OpenBlogId) {
      case 0:
        return (
          <div id="BlogsDetail">
            <div
              className="bannerDetail"
              style={{ backgroundImage: "url(" + blog1detailbanner + ")" }}>
              <h2 className="banner__title strong">
                <span>INCREASING CUSTOMER RETENTION</span> <br></br>
                Rates By Just 5% can<br></br>
                <span>BOOST PROFIT</span> By 25% - 95%
              </h2>
            </div>
            <div className="blogslanding BlogDetailSection">
              <div className="blogslanding_blogs_wrapper">
                <span
                  className="backbtnblog"
                  onClick={() => {
                    myContext.setBlogDetailPageIsOpen(false);
                  }}>
                  Back
                </span>
                {/* <div className="flex">
                  {[BlogModel[OpenBlogId]].map((Blog) => (
                    <div className="blogs_box__item" key={Blog.BlogId}>
                      <div className="blogs_box_item_Icon__wrapper">
                        <img src={Blog.image} alt="BannerRightImage" />
                      </div>
                      <div className="blogs_box_item_content_wrapper">
                        <div className="blogs_box_item_content_flex">
                          <span className="category">{Blog.Category}</span>
                          <span className="date">{Blog.Date}</span>
                        </div>
                        <Title Title={Blog.Title} size="xsm" />
                      </div>
                    </div>
                  ))}
                </div> */}
              </div>

              <div className="blogslanding_blogs_wrapper">
                <div className="m-medium">
                  <Title
                    Title="Customer Retention Helps Drive Dealership Success"
                    size="md"
                  />
                </div>
                <Para
                  para="In the fast-paced world of the automotive industry, dealerships are constantly striving to attract new customers. However, amidst the focus on expanding the customer base, another crucial aspect that is sometimes overlooked is customer retention. Building a loyal customer base is key to your dealership's long-term success and sustainability."
                  style="normal"
                  size="md"
                />
                <div className="m-medium">
                  <Title Title="What is Customer Retention?" size="md" />
                </div>
                <Para
                  para="Customer retention refers to the ability of a business to keep their existing customers coming back for repeat business. It's about fostering strong and lasting relationships with clients beyond the initial sale. When customers return to a dealership for service, maintenance, or to purchase a new vehicle, it demonstrates their satisfaction and trust in the dealership's products and services. Simply put, the ROI for customer loyalty cannot be overstated!"
                  style="normal"
                  size="md"
                />
                <div className="m-medium">
                  <Title
                    Title="The Power of Customer Retention in Auto Dealerships:"
                    size="md"
                  />
                </div>

                <div className="blog_detail_leftright_wrapper">
                  <div className="left">
                    <ul>
                      <li>
                        <Title
                          Title="Increased Customer Lifetime Value (CLV)"
                          size="sm"
                        />
                      </li>
                    </ul>
                    <Para
                      para=" Customer retention directly impacts the Customer Lifetime Value, which is the total value a customer brings to a dealership over their entire relationship. Repeat customers spend more on average and are more likely to try out new products or services offered by the dealership."
                      style="normal"
                      size="md"
                    />

                    <ul>
                      <li>
                        <Title Title="Cost-Effectiveness " size="sm" />
                      </li>
                    </ul>
                    <Para
                      para="Acquiring new customers is significantly more expensive than retaining existing ones. Building relationships with customers who already know and trust your dealership requires fewer marketing efforts and resources compared to attracting entirely new prospects."
                      style="normal"
                      size="md"
                    />

                    <ul>
                      <li>
                        <Title
                          Title="Positive Word-of-Mouth and Referrals "
                          size="sm"
                        />
                      </li>
                    </ul>
                    <Para
                      para="Happy and satisfied customers become brand advocates, spreading positive word-of-mouth about their experiences. Referrals from loyal customers can be a powerful source of new business for auto dealerships."
                      style="normal"
                      size="md"
                    />
                  </div>
                  <div className="right">
                    <img src={blogimg1} alt="BannerRightImage" />
                  </div>
                </div>

                <div className="m-medium">
                  <Title
                    Title="Effective Strategies for Customer Retention"
                    size="md"
                  />
                </div>

                <div className="blog_detail_leftright_wrapper">
                  <div className="left">
                    <ul>
                      <li>
                        <Title
                          Title=" Exceptional Customer Service "
                          size="sm"
                        />
                      </li>
                    </ul>
                    <Para
                      para="Providing excellent customer service is the cornerstone of customer retention. Train your staff to be attentive, friendly, and knowledgeable. Ensure prompt response to inquiries and complaints, making customers feel valued and appreciated."
                      style="normal"
                      size="md"
                    />

                    <ul>
                      <li>
                        <Title Title="Personalized Communication: " size="sm" />
                      </li>
                    </ul>
                    <Para
                      para="Utilize customer data to personalize interactions and communications. Address customers by their names, acknowledge their previous purchases, and offer relevant promotions or discounts tailored to their preferences."
                      style="normal"
                      size="md"
                    />

                    <ul>
                      <li>
                        <Title Title="Loyalty Programs: " size="sm" />
                      </li>
                    </ul>
                    <Para
                      para="Implement loyalty programs that reward repeat business. Offer exclusive perks, discounts, or special offers to customers who have been with the dealership for a certain period or have made multiple purchases."
                      style="normal"
                      size="md"
                    />

                    <ul>
                      <li>
                        <Title Title="Regular Follow-Ups: " size="sm" />
                      </li>
                    </ul>
                    <Para
                      para="Stay in touch with customers even after a sale. Send personalized thank-you notes, follow-up calls, or emails to check their satisfaction and address any concerns they might have."
                      style="normal"
                      size="md"
                    />
                  </div>
                  <div className="right">
                    <img src={blogimg2} alt="BannerRightImage" />
                  </div>
                </div>

                <div className="m-medium">
                  <Title Title="Statistics on Customer Retention" size="md" />
                </div>
                <Title
                  Title="According to these statistics, the importance of customer retention is clear:"
                  size="sm"
                />
                <ul>
                  <li>
                    <Para
                      para="Increasing customer retention rates by just 5% can boost profits by 25% to 95%. (Source: Bain & Company)"
                      style="normal"
                      size="md"
                    />
                  </li>
                  <li>
                    <Para
                      para="Loyal customers are five times more likely to repurchase and four times more likely to refer others to the dealership. (Source: Harvard Business Review)"
                      style="normal"
                      size="md"
                    />
                  </li>
                  <li>
                    <Para
                      para="The probability of selling to an existing customer is 60-70%, while the likelihood of selling to a new prospect is only 5-20%. (Source: Marketing Metrics)"
                      style="normal"
                      size="md"
                    />
                  </li>
                </ul>

                <Para
                  para="In the fiercely competitive automotive industry, customer retention is the engine that drives sustainable growth and success for auto dealerships. By focusing on building lasting relationships with customers through exceptional service, personalized communication, and loyalty programs, dealerships can boost customer lifetime value, reduce marketing costs, and enjoy the ripple effect of positive word-of-mouth marketing. Embracing customer retention as a core business strategy will set any dealership on the road to long-lasting prosperity!"
                  style="normal"
                  size="md"
                />
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div id="BlogsDetail">
            <div
              className="bannerDetail"
              style={{ backgroundImage: "url(" + blog2detailbanner + ")" }}>
              <h2 className="banner__title strong">
                LEARN WHAT <br></br>
                <span>IBIZI</span> CAN DO <br></br> FOR YOU
              </h2>
            </div>
            <div className="blogslanding BlogDetailSection">
              <div className="blogslanding_blogs_wrapper">
                <span
                  className="backbtnblog"
                  onClick={() => {
                    myContext.setBlogDetailPageIsOpen(false);
                  }}>
                  Back
                </span>
                {/* <div className="flex">
                  {[BlogModel[OpenBlogId]].map((Blog) => (
                    <div className="blogs_box__item" key={Blog.BlogId}>
                      <div className="blogs_box_item_Icon__wrapper">
                        <img src={Blog.image} alt="BannerRightImage" />
                      </div>
                      <div className="blogs_box_item_content_wrapper">
                        <div className="blogs_box_item_content_flex">
                          <span className="category">{Blog.Category}</span>
                          <span className="date">{Blog.Date}</span>
                        </div>
                        <Title Title={Blog.Title} size="xsm" />
                      </div>
                    </div>
                  ))}
                </div> */}
              </div>

              <div className="blogslanding_blogs_wrapper">
                <div className="m-medium">
                  <Title
                    Title="How Auto Dealers Can Resolve Common Pain Points With IBIZI"
                    size="md"
                  />
                </div>
                <Para
                  para="In today’s automotive industry landscape, dealerships constantly strive to enhance the buyer experience, streamline operations, and strengthen customer loyalty. However, the road to achieving these goals is often paved with challenges. That’s where IBIZI comes in! IBIZI is an innovative app designed to transform how auto dealerships operate and interact with their customers, effectively addressing many of their most common pain points."
                  style="normal"
                  size="md"
                />
                <br></br>
                <Title
                  Title="Pain Point 1: Customer Engagement and Loyalty"
                  size="sm"
                />
                <br></br>

                <div className={`theme_para normal md`}>
                  As we covered in our{" "}
                  <a href="#BlogsDetail">
                    <strong
                      style={{
                        color: "black",
                      }}
                      onClick={() => {
                        myContext.setBlogDetailPageIsOpen(true);
                        setOpenBlogId(OpenBlogId - 1);
                      }}>
                      previous blog,{" "} 
                    </strong>
                  </a>
                  maintaining customer loyalty is paramount to any business,
                  yet it can be challenging to keep customers engaged and
                  incentivized to return once they’ve completed their vehicle
                  purchase. IBIZI introduces a gamified loyalty system through
                  which customers earn points with every purchase or survey.
                  These points can be redeemed for future services or even used
                  towards a new vehicle, fostering loyalty and encouraging
                  repeat business.
                </div>
                <br></br>
                <Title Title="Pain Point 2: Communication Hassles" size="sm" />
                <br></br>
                <Para
                  para="Dealerships often struggle with managing effective communication channels. Missed calls, voicemails, and delayed responses can frustrate both customers and staff. IBIZI steps in with its built-in chat feature, ensuring seamless and real-time communication between dealerships and customers. From inquiries about service appointments to questions about inventory, this feature eliminates communication barriers and provides instant support."
                  style="normal"
                  size="md"
                />
                <br></br>
                <Title Title="Pain Point 3: Missed Opportunities" size="sm" />
                <br></br>
                <Para
                  para="Dealerships may miss out on engaging customers with exclusive offers or occasionally fail to notify them about upcoming promotions. IBIZI's in-app exclusive specials feature ensures that dealerships can communicate and extend unique offers directly to app users. What's more, IBIZI  can help overcome the challenge of declined service items that can impact revenue potential. Imagine a scenario where a customer declines a vital service during their service bay visit. With IBIZI, dealerships can follow up with the customer through the app, presenting them with a special discount to encourage reconsideration. Capitalizing on these commonly missed opportunities not only helps customers save money but also underlines the dealership's commitment to their vehicle's well-being. This personalized approach increases the likelihood of attracting customers to access special deals and options, driving more foot traffic and revenue."
                  style="normal"
                  size="md"
                />
                <br></br>
                <Title
                  Title="Pain Point 4: Appointment Scheduling Woes"
                  size="sm"
                />
                <br></br>
                <Para
                  para="Coordinating service appointments can be a logistical nightmare for some dealerships. Traditional methods can lead to overbooked or underutilized time slots, resulting in inefficiency and frustration. The IBIZI App revolutionizes appointment scheduling, allowing customers to book services with ease. Dealerships can optimize their service bays, ensure efficient resource allocation, and reduce wait times for customers – a win-win for all."
                  style="normal"
                  size="md"
                />
                <br></br>
                <Title Title="Pain Point 5: Inventory Visibility" size="sm" />
                <br></br>
                <Para
                  para="Dealerships often struggle with effectively showcasing their inventory and promotions to potential buyers. The IBIZI App provides a solution by allowing customers to explore the dealership's car lot from the comfort of their mobile device. Dealers can also implement special app-only pricing for returning customers. These features not only enhance the customer experience but also boost transparency and accessibility for dealerships, helping them showcase their offerings more effectively."
                  style="normal"
                  size="md"
                />
                <div className="blog_detail_leftright_wrapper">
                  <div className="left">
                    <br></br>
                    <Title
                      Title="Pain Point 6: Multi-Vehicle Management"
                      size="sm"
                    />
                    <br></br>
                    <Para
                      para="For customers with multiple vehicles, tracking maintenance appointments for each can be overwhelming. IBIZI simplifies this process by enabling customers to manage appointments for all their automobiles within a single platform. This feature streamlines the experience for customers and allows dealerships to cater to a broader range of automotive needs."
                      style="normal"
                      size="md"
                    />

                    <br></br>
                    <Title
                      Title="Pain Point 7: Reminders and Notifications"
                      size="sm"
                    />
                    <br></br>
                    <Para
                      para="Customers may overlook essential maintenance tasks or appointments, leading to potential vehicle issues and dissatisfaction. IBIZI eliminates this concern by sending timely reminders and notifications to customers for upcoming service appointments. This proactive feature ensures that customers stay on top of their vehicle's maintenance, enhancing their ownership experience. Moreover, IBIZI takes customer engagement further by seamlessly integrating its scheduling feature with these reminders. Customers can easily reschedule or confirm their upcoming service appointments directly within the app, ensuring a hassle-free experience. This not only keeps their vehicles in prime condition but also brings them back to the dealership for their service needs, creating a cycle of customer loyalty and increased business."
                      style="normal"
                      size="md"
                    />
                  </div>
                  <div className="right blog2right">
                    <img src={blog2detailsectionimage} alt="BannerRightImage" />
                  </div>
                </div>
                <div className="m-medium">
                  <Title Title="Simple Solutions in One Easy App" size="md" />
                </div>
                <Para
                  para="IBIZI's comprehensive suite of features directly addresses the most common pain points faced by today’s auto dealerships. By streamlining communication, optimizing appointment scheduling, fostering customer loyalty, enhancing inventory visibility, and simplifying multi-vehicle management, IBIZI empowers dealerships to provide an exceptional customer experience while increasing operational efficiency."
                  style="normal"
                  size="md"
                />
                <Para
                  para="Are You Ready to Revolutionize Your Dealership? Discover the power of IBIZI firsthand by scheduling a demo today. Experience how IBIZI can transform your dealership's operations, boost customer engagement, and drive revenue. Reach out to our team to book your personalized demo and embark on a journey toward a more efficient and customer-centric dealership experience!"
                  style="normal"
                  size="md"
                />
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <>
            <div className="blogslanding_blogs_wrapper">
              <span
                className="backbtnblog"
                onClick={() => {
                  myContext.setBlogDetailPageIsOpen(false);
                }}>
                Back
              </span>
              <div className="flex">
                {[BlogModel[OpenBlogId]].map((Blog) => (
                  <div className="blogs_box__item" key={Blog.BlogId}>
                    <div className="blogs_box_item_Icon__wrapper">
                      <img src={Blog.image} alt="BannerRightImage" />
                    </div>
                    <div className="blogs_box_item_content_wrapper">
                      <div className="blogs_box_item_content_flex">
                        <span className="category">{Blog.Category}</span>
                        <span className="date">{Blog.Date}</span>
                      </div>
                      <Title Title={Blog.Title} size="xsm" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  }, [OpenBlogId]);

  

  return (
    <>
      {myContext.BlogDetailPageIsOpen ? (
        renderContentBlogContent()
      ) : (
        <>
          <Banner from="blog" />
          <div className="blogslanding" id="about">
            {/* <div className="blogslanding_section_wrapper">
              <div className="flex">
                <div className="left">
                  <h2 className="banner__title strong m-b-20">
                    <span>Our Blogs</span>
                  </h2>
                  <Para
                    para="
                    View our blogs and get to know about the current trends"
                    style="normal"
                    size="xl"
                  />
                </div>
              </div>
            </div> */}

            {/* Latest Blogs */}
            {/* <div className="blogslanding_blogs_wrapper">
              <a href="#BlogsDetail">
                <div className="flex">
                  {[BlogModel[BlogModel.length - 1]].map((Blog) => (
                    <div
                      className="blogs_box__item"
                      key={Blog.BlogId}
                      onClick={() => {
                        myContext.setBlogDetailPageIsOpen(true);
                        setOpenBlogId(Blog.BlogId);
                      }}>
                      <div className="blogs_box_item_Icon__wrapper">
                        <img src={Blog.image} alt="BannerRightImage" />
                      </div>
                      <div className="blogs_box_item_content_wrapper">
                      </div>
                    </div>
                  ))}
                </div>
              </a>
            </div> */}

            <div className="blogslanding_blogs_wrapper">
              {/* All Blogs */}
              <Title Title="All Blogs" size="mdl" />
              <a href="#BlogsDetail">
                <div className="flex allblogs">
                  {BlogModel.map((Blog) => (
                    <div
                      className="allblogframe"
                      onClick={() => {
                        myContext.setBlogDetailPageIsOpen(true);
                        setOpenBlogId(Blog.BlogId);
                      }}>
                      <div className="blogs_box__item" key={Blog.BlogId}>
                        <div className="blogs_box_item_Icon__wrapper">
                          <img src={Blog.image} alt="BannerRightImage" />
                        </div>
                        <div className="blogs_box_item_content_wrapper">
                          <div className="blogs_box_item_content_flex">
                            {/* <span className="category">{Blog.Category}</span> */}
                          </div>
                        </div>
                      </div>
                      <div className="blogs_box_item_content_wrapper2">
                        <Title Title={Blog.Title} size="xsm" />
                        <div className="blogs_box_item_content_flex">
                          {/* <span className="date">{Blog.Date}</span> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};
