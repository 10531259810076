import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import heroimg from '../Assets/images/loading.gif'


export const EmailForm = (props) => {
  const form = useRef();
  const [loader, setloader] = useState(false);
  const [mssg, setmssg] = useState('');
  const [mssgstate, setmssgstate] = useState(false);
  const [schduldeName, setschduldeName] = useState("");
  const [schduldeemail, setschduldeemail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);


  const sendEmail = (e) => {
    e.preventDefault();


    // const token = captchaRef.current.getValue();
    // let body_user = {
    //     "secret": '6LduFyojAAAAAB5RvGDveQ10tqH9_T0YPVcy5RDD',
    //     "response": token,
    //     "remoteip": ''
    // }
    // let end_point = 'https://www.google.com/recaptcha/api/siteverify';
    // const url = end_point;
    // fetch(url,{
    //     method: 'POST',
    //     mode: "cors",
    //     headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(body_user),
    // })
    // .then(res => res.json())
    // .then((result) => {
    //     console.log(result);
    // },
    // (error) => {
    //     console.log(error)
    // }) 


    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    
    if(name === "") {
        document.querySelector(".errusername").innerHTML = "required";
    } else {
        document.querySelector(".errusername").innerHTML = "";
    }

    if(email === "") {
        document.querySelector(".erruseremail").innerHTML = "required";
    } else {
        document.querySelector(".erruseremail").innerHTML = "";
    }

    if(email !== "" && name !== "") {
        setloader(true);
        const value = e.target.value;
        emailjs.sendForm('service_53ni659', 'template_d85jg19', form.current, 'uzpOpmCl1Jfkn49oY')
        .then((result) => {
            console.log(result.text);
            setloader(false);
            setmssg(result.text);
            if(result.text === "OK") {
                setmssg("Thankyou! We will get back to you..");
            } else {
                setmssg(result.text);
            }
            setmssgstate(true);
            setTimeout(() => {
            setmssgstate(false);
            }, "2000")
        }, (error) => {
            console.log(error.text);
            setloader(false);
            setmssg(error.text);
            setmssgstate(true);
            setTimeout(() => {
                setmssgstate(false);
            }, "2000")
        });
    }   
  };



  const sendEmailContactForm = (e) => {
    e.preventDefault();
    // const token = captchaRef.current.getValue();
    // let body_user = {
    //     "secret": '6LduFyojAAAAAB5RvGDveQ10tqH9_T0YPVcy5RDD',
    //     "response": token,
    //     "remoteip": ''
    // }
    // let end_point = 'https://www.google.com/recaptcha/api/siteverify';
    // const url = end_point;
    // fetch(url,{
    //     method: 'POST',
    //     mode: "cors",
    //     headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(body_user),
    // })
    // .then(res => res.json())
    // .then((result) => {
    //     console.log(result);
    // },
    // (error) => {
    //     console.log(error)
    // }) 

  

    // let end_point = 'https://www.google.com/recaptcha/api/siteverify';
    // //api url for right
    // let api_url = end_point;
    // const url = api_url;
    // axios(
    //   {
    //     method: 'post',
    //     url: url,
    //     mode: "cors",
    //     data: {
    //         "secret": '6LduFyojAAAAAB5RvGDveQ10tqH9_T0YPVcy5RDD',
    //         "response": token,
    //         "remoteip": ''
    //     },
    //     headers: {
    //         "Content-Type": "application/json",
    //     }
    //   }
    // )
    // .then(res => {
    //     console.log(res);
    // })
    // .catch((error) => console.log(error)) 



    // captchaRef.current.reset();




    

    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    
    if(name === "") {
        document.querySelector(".errusername").innerHTML = "required";
    } else {
        document.querySelector(".errusername").innerHTML = "";
    }

    if(email === "") {
        document.querySelector(".erruseremail").innerHTML = "required";
    } else {
        document.querySelector(".erruseremail").innerHTML = "";
    }

    if(email !== "" && name !== "") {
        setloader(true);
        const value = e.target.value;
        emailjs.sendForm('service_1sutli7', 'template_l2gqo1r', form.current, 'ZNIT450S__abFkijz')
        .then((result) => {
            console.log(result.text);
            setloader(false);
            setmssg(result.text);
            if(result.text === "OK") {
                setmssg("Thankyou! We will get back to you..");
            } else {
                setmssg(result.text);
            }
            setmssgstate(true);
            setTimeout(() => {
            setmssgstate(false);
            }, "2000")
        }, (error) => {
            console.log(error.text);
            setloader(false);
            setmssg(error.text);
            setmssgstate(true);
            setTimeout(() => {
                setmssgstate(false);
            }, "2000")
        });
    }   
  };


  const handleNameChange = (event) => {
    const inputName = event.target.value;
    // Apply character limit (e.g., 20 characters)
    if (inputName.length <= 26) {
        setschduldeName(inputName.replace(/[^a-zA-Z\s]/g, ""));
    }
  };

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setschduldeemail(inputEmail);

    // Email validation regular expression pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputEmail);
    setIsValidEmail(isValid);
  };


  return props.from === "contactform" ? (
    <form ref={form} onSubmit={sendEmailContactForm}>
      {loader && (
        <div className="loader_form">
          <img className="" src={heroimg} alt="BannerRightImage" />
        </div>
      )}
      {mssgstate && (
        <div className="loader_form">
          <span>{mssg}</span>
        </div>
      )}
      <div className="form_group">
        <label id="names">Name</label>
        <input
          type="text"
          id="name"
          htmlFor="names"
          name="user_namecntct"></input>
        <span className=" err errusername"></span>
      </div>

      <div className="form_group">
        <label id="emails">Email</label>
        <input
          type="email"
          id="email"
          htmlFor="emails"
          name="user_emailcntct"
        />
        <span className="err erruseremail"></span>
      </div>

      <div className="form_group">
        <label id="mssg">How may we help you?</label>
        <textarea name="messagecntct" htmlFor="mssg" className="mt-5px" />
      </div>

      <div className="form_button_wrapper msggroup">
        <div className="theme__button black ">
          <input type="submit" className="" value="Submit" />
          {/* <ReCAPTCHA
                    sitekey={'6LduFyojAAAAAAChA3RHGM-gKv5FCqluVeYXoVNE'}
                    ref={captchaRef}
                /> */}
        </div>
      </div>
    </form>
  ) : (
    <>
      <form ref={form} onSubmit={sendEmail}>
        {loader && (
          <div className="loader_form">
            <img className="" src={heroimg} alt="BannerRightImage" />
          </div>
        )}
        {mssgstate && (
          <div className="loader_form">
            <span>{mssg}</span>
          </div>
        )}
        <div className="form_group">
          <label id="names">Name</label>
          <input
            type="text"
            id="name"
            htmlFor="names"
            name="user_name"
            value={schduldeName}
            onChange={handleNameChange}></input>
          <span className=" err errusername"></span>
        </div>

        <div className="form_group">
          <label id="emails">Email</label>
          <input
            type="email"
            id="email"
            htmlFor="emails"
            name="user_email"
            value={schduldeemail}
            onChange={handleEmailChange}
          />
          {!isValidEmail && (
            <span className="err erruseremail">
              Please enter a valid email address.
            </span>
          )}
          <span className="err erruseremail"></span>
        </div>
        <div className="form_group">
          <label id="dealerships">Dealership</label>
          <input type="text" name="dealership" htmlFor="dealerships" />
          <span className="err erruseremail"></span>
        </div>

        <div className="form_group">
          <label id="phones">Phone</label>
          <input type="text" id="name" htmlFor="phones" name="phone"></input>
          <span className=" err errusername"></span>
        </div>

        <div className="form_group">
          <label id="citys">City</label>
          <input type="text" id="citys" htmlFor="citys" name="city" />
          <span className="err erruseremail"></span>
        </div>
        <div className="form_group">
          <label id="states">State</label>
          <textarea name="state" htmlFor="states" className="mt-5px" />
        </div>

        <div className="form_button_wrapper msggroup">
          <div className="theme__button black ">
            <input type="submit" className="" value="Schedule" />
            {/* <ReCAPTCHA
                        sitekey={'6LduFyojAAAAAAChA3RHGM-gKv5FCqluVeYXoVNE'}
                        ref={captchaRef}
                    /> */}
          </div>
        </div>
      </form>
    </>
  );
};